import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import React from 'react';
import { CssButtonOne } from '../../../Button/index.style';

const FooterModal = ({
  onClick, submitButton, t, t1, action,
}) => {
  let backgroundButton;

  if (action === 'delete') backgroundButton = '#CC5F5F';
  else if (action === 'reject') backgroundButton = '#CC5F5F';
  else backgroundButton = '#5570F1';
  return (
    <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
      <CssButtonOne
        sx={{
          bgcolor: '#fff',
          width: '180px',
          height: '58px',
          border: '1px solid #5570F1',
          borderRadius: '12px',
          mr: '10px',
        }}
        onClick={onClick}
      >
        {t}
      </CssButtonOne>
      <Button
        type="submit"
        sx={{
          bgcolor: backgroundButton,
          width: '180px',
          height: '58px',
          color: '#fff',
          borderRadius: '12px',
          '&:hover': {
            background: backgroundButton,
            opacity: 0.8,
            color: '#f8f8f8',
          },
        }}
      >
        <Typography sx={{ fontSize: '20px', lineHeight: '24px', textTransform: 'capitalize' }}>{submitButton || t1}</Typography>
      </Button>
    </Box>
  );
};
export default FooterModal;
