import { Box, Link } from '@mui/material';
import React from 'react';

const CardRow = ({
  item, type, param, label, transform,
}) => {
  let value;

  if (type === 'link') {
    value = <Link href={item[param]}>File</Link>;
  } else {
    value = item[param];

    if (transform) {
      value = transform(value, item);
    }
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', padding: '2px 10px 0px 10px' }}>
      <Box sx={{
        fontFamily: 'Inter', fontWeight: '600', fontSize: '13px', color: '#45464E', marginRight: 'auto',
      }}
      >
        {label}
      </Box>

      <Box
        sx={{
          fontFamily: 'Inter',
          fontSize: '13px',
          color: '#6E7079',
        }}
      >
        {value}
      </Box>
    </Box>
  );
};

export default CardRow;
