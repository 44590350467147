import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box } from '@mui/material';

const PDF = ({ file }) => {
  // Config
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // Hook
  const pdfWrapper = useRef(null);
  const [initialWidth, setInitialWidth] = useState(null);
  // Callback
  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  };
  // Hook
  useEffect(() => {
    window.addEventListener('resize', setPdfSize);
    setPdfSize();
    return () => {
      window.removeEventListener('resize', setPdfSize);
    };
  }, []);

  return (
    <Box sx={{ width: '600px', maxWidth: '95%' }} ref={pdfWrapper}>
      <Document file={file} style={{ width: '100%' }}>
        <Page pageNumber={1} width={initialWidth} />
      </Document>
    </Box>
  );
};

export default PDF;
