import React from 'react';
import { useParams } from 'react-router-dom';
import PDF from '@todcode/components/src/component/PDF';
import {
  Box, Button, Container, Link,
} from '@mui/material';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
import { useGetTransactionQuery } from '../../features/transactions/transactionsApiSlice';

const PDFPage = () => {
  // Hook
  const { id } = useParams();
  const { t } = useTranslation();

  // RTK Query
  const { data: transaction = {} } = useGetTransactionQuery(id);

  return (
    <Container>
      <Box sx={{
        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
      }}
      >
        {
          transaction && (
            <>
              <PDF file={transaction?.ownershipFileUrl} />
              <Box sx={{
                width: '600px',
                maxWidth: '95%',
                display: 'flex',
                background: '#fff',
                borderRadius: '12px',
                padding: '20px',
                mt: '15px',
              }}
              >
                <Button
                  component={Link}
                  href={transaction.ownershipFileUrl}
                  download
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  fullWidth
                  sx={{
                    display: 'flex',
                    height: '36px',
                    width: '205px',
                    bgcolor: '#5570F1',
                    borderRadius: '12px',
                    textTransform: 'none',
                    '&:hover': {
                      bgcolor: '#97A5EB',
                    },
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    mr: 'auto',
                  }}
                >
                  {t('button.download')}
                </Button>
                <Button
                  component={Link}
                  href={`mailto:example@gmail.com?body=${transaction.ownershipFileUrl}`}
                  variant="contained"
                  startIcon={<LocalMallIcon />}
                  fullWidth
                  sx={{
                    display: 'flex',
                    height: '36px',
                    width: '205px',
                    bgcolor: '#5570F1',
                    borderRadius: '12px',
                    textTransform: 'none',
                    '&:hover': {
                      bgcolor: '#97A5EB',
                    },
                    fontFamily: 'Inter',
                    fontSize: '14px',
                  }}
                >
                  {t('button.mail')}
                </Button>
              </Box>
            </>
          )
        }
      </Box>
    </Container>
  );
};

export default PDFPage;
