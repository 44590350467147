import { Typography } from '@mui/material';

import { labelConfig } from './index.config';

const Label = ({ value, color }) => {
  return (
    <Typography sx={{
      display: 'inline-block',
      background: labelConfig[color].background,
      color: labelConfig[color].color,
      padding: '4px 11px',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontSize: '12px',
    }}
    >
      {value}
    </Typography>
  );
};

export default Label;
