import React, { useMemo, useEffect } from 'react';
import {
  Container, Box,
} from '@mui/material';
import {
  SelaaTable, SelaaCards, useModal, formatDateTime,
} from '@todcode/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { intentModal, transactionModal } from '@todcode/components/src/component/ModalConfig';
import {
  historyParams, historyParentParams,
} from './index.config';
import { historyApiSlice } from '../../features/history/historyApiSlice';
import { selectAccountType } from '../../features/auth/authSlice';
import { useGetSubAccountsQuery } from '../../features/subAccounts/subaccApiSlice';

const History = () => {
  // Selector
  const accountType = useSelector(selectAccountType);

  // RTK Query
  const [trigger, { data: historyRaw = [] }] = historyApiSlice.useLazyGetHistoryQuery();
  const { data: lenders = [] } = useGetSubAccountsQuery();

  // Hook
  const { t } = useTranslation();
  const history = useMemo(() => {
    return historyRaw.map((item) => {
      const lenderName = lenders.find((lender) => item.lenderId === lender.id)?.name;

      return { ...item, lenderName };
    });
  }, [historyRaw, lenders]);

  useEffect(() => {
    trigger();
  }, []);

  const { modal, show } = useModal();

  // Callback
  const handleMore = async ({ transaction: transactionDate, intent }) => {
    const values = {};
    let params = [];

    if (transactionDate) {
      const dateTime = formatDateTime(transactionDate.dateTime);
      const transaction = { ...transactionDate, dateTime };

      values.transaction = transaction;
      params = [...params, ...transactionModal];
    }

    if (intent) {
      const dateTime = formatDateTime(intent.dateTime);
      const transaction = { ...intent, dateTime };
      values.intent = transaction;
      params = [...params, ...intentModal];
    }

    show({
      title: t('modal.title.details'),
      config: params,
      initialValues: values,
      readOnly: true,
    });
  };

  const handleButtonClick = (action, data) => {
    switch (action) {
      case 'moreInfo':
        return handleMore(data);
      default:
        break;
    }
  };

  return (
    <Container>
      {modal}
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          t={t}
          title={t('titleTable.history')}
          data={history}
          params={accountType === 'parent' ? historyParentParams : historyParams}
          onButtonClick={handleButtonClick}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={history}
          params={accountType === 'parent' ? historyParentParams : historyParams}
          visibleFields={6}
          onButtonClick={handleButtonClick}
        />
      </Box>

    </Container>
  );
};

export default History;
