import React, { useState } from 'react';
import {
  Box, Button, IconButton, InputAdornment, Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import logo from '../../layout/Main/component/NavigationComponent/img/selaa-logo.png';
import CssTextField from '../../component/Input/index.styled';
import { ReactComponent as Message } from './img/Message.svg';
import { ReactComponent as Password } from './img/Lock.svg';
import { ValidationSchema } from './LoginValidationSchemas';

const initialState = {
  username: '',
  password: '',
};

export const Login = ({ onLogin }) => {
  // Hook
  const [valuesPass, setValuesPass] = useState({
    password: '',
    showPassword: false,
  });
  const { t } = useTranslation();

  // Callback
  const handleLogin = (values) => onLogin(values);

  const handleClickShowPassword = () => {
    setValuesPass({
      ...valuesPass,
      showPassword: !valuesPass.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: '12px',
      bgcolor: '#fff',
      p: '34px',
      width: '500px',
      maxWidth: '95%',
    }}
    >
      <Box sx={{ pt: { xs: '10px', md: '15px' }, pb: { xs: '15px', md: '20px', lg: '35px' } }}>
        <img src={logo} alt="" style={{ width: '120px' }} />
      </Box>
      <Box sx={{ pb: '8px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{t('login.title')}</Typography>
      </Box>
      <Box sx={{ pb: { xs: '30px', md: '40px', lg: '60px' } }}>
        <Typography sx={{ fontSize: '14px', color: '#8B8D97' }}>
          {t('login.subTitle')}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'column', pb: '12px', width: '100%',
      }}
      >
        <Formik  validate={false}
            validateOnChange={false}
             initialValues={initialState} onSubmit={handleLogin} validationSchema={ValidationSchema}>
          {({
            values,
            errors,
            touched,
            handleChange,
          }) => (
            <Form>
              <Box sx={{ width: '100%' }}>
                <CssTextField
                  sx={{ pb: '30px' }}
                  placeholder={t('login.userName')}
                  name="username"
                  onChange={handleChange}
                  value={values.username}
                  fullWidth
                  error={errors.username && touched.username && errors.username}
                  helperText={errors.username && touched.username && errors.username}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Message />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box sx={{ width: '100%' }}>
                <CssTextField
                  name="password"
                  type={valuesPass.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  fullWidth
                  placeholder={t('login.password')}
                  error={errors.password && touched.password && errors.password}
                  helperText={errors.password && touched.password && errors.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {valuesPass.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{
                display: 'flex', justifyContent: 'flex-end', width: '100%', pb: { xs: '15px', md: '30px', lg: '48px' },
              }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {' '}
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    height: '58px', bgcolor: '#5570F1', borderRadius: '12px', textTransform: 'none', width: '180px',
                  }}
                >
                  <Typography sx={{ fontFamily: 'Inter', fontSize: '20px' }}>
                    Login
                  </Typography>
                </Button>
              </Box>
            </Form>
          )}

        </Formik>

      </Box>

    </Box>
  );
};
