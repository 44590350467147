import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    email: null,
    token: null,
    refreshToken: null,
    expireAt: null,
  },
  reducers: {
    setCredentials: (state, { payload: { access_token } }) => {
      state.token = access_token;
    },
    logOut: (state) => {
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
