import { apiSlice } from '../../store/api/apiSlice';

export const approveApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    approve: builder.mutation({
      query: (id) => ({
        url: `/operations/approve/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Transactions', 'AvailableAmount', 'Intents'],
    }),
    reject: builder.mutation({
      query: (id) => ({
        url: `/operations/reject/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Transactions', 'AvailableAmount', 'Intents'],
    }),
  }),
});

export const {
  useApproveMutation,
  useRejectMutation,
} = approveApiSlice;
