import { Button } from '@mui/material';

export const MButton = ({ children, onClick }) => {
  return (
    <Button
      size="small"
      aria-label="edit"
      sx={{
        maxWidth: '35px',
        minWidth: '35px',
        bgcolor: '#5570F1',
        boxShadow: 'none',
        '&:hover': { backgroundColor: '#97a5eb' },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
