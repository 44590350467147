import { Button, styled } from '@mui/material';

export const CssButtonOne = styled(Button)({
  width: '180px',
  height: '58px',
  background: '#5570F1',
  borderRadius: '12px',
  fontFamily: 'Inter',
  fontSize: '20px',
  textTransform: 'capitalize',
  '&:hover': {
    background: '#5C6EC6',
    color: '#fff',
  },
  '&:active': {
    background: '#5B6EC6',
    boxShadow: 'inset 0px 4px 20px rgba(0, 0, 0, 0.2)',
  },
  '&:focus': {
    boxShadow: 'inset 0px 4px 20px rgba(0, 0, 0, 0.2)',
  },
});

export const CssButtonTwo = styled(Button)({
  '&:hover': {
    backgroundColor: '#0069d9',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});
