import React from 'react';
import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { style } from './index.style';

const ModalCopyLink = ({ data, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Link to set a password
        </Typography>
        <Box sx={style.input}>
          <Typography
            id="modal-modal-description"
            sx={style.title}
          >
            {data}
          </Typography>
          <Button
            onClick={() => navigator.clipboard.writeText(data)}
            sx={{
              display: 'flex',
              height: '36px',
              width: '80px',
              bgcolor: '#5570F1',
              borderRadius: ' 0 12px 12px 0 ',
              textTransform: 'none',
              '&:hover': {
                bgcolor: '#97A5EB',
              },
            }}
          >
            <FileCopyIcon
              sx={{ pr: '5px', color: '#FFF', fontSize: '20px' }}
            />
          </Button>
        </Box>

      </Box>
    </Modal>
  );
};

export default ModalCopyLink;
