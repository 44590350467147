import { Trans } from 'react-i18next';
import { MoreButtonIntent } from '@todcode/components/src/component/MoreButtonIntent';
import { formatDateTime } from '@todcode/components';

export const historyParams = [

  {
    param: 'type',
    label: <Trans i18nKey="historyTableHeads.type" />,
    mix: {
      align: 'center',
    },
  },
  {
    param: 'amount',
    label: <Trans i18nKey="historyTableHeads.amount" />,
  },
  {
    param: 'dateTime',
    label: <Trans i18nKey="historyTableHeads.dateTime" />,
    transform: formatDateTime,
  },
  {
    label: <Trans i18nKey="historyTableHeads.actions" />,
    align: 'center',
    type: 'info',
    buttons: [
      {
        Button: MoreButtonIntent,
        action: 'moreInfo',
        isActive: (item) => item.transaction || item.intent,
      },
    ],
  },
];

export const historyParentParams = [
  {
    param: 'lenderName',
    label: <Trans i18nKey="historyTableHeads.lender" />,
  },
  ...historyParams,
];
