import { Trans } from 'react-i18next';
import { ResetButton } from '@todcode/components/src/component/ResetButton';

export const subAccountsParams = [
  {
    param: 'name',
    label: <Trans i18nKey="subAccTableHeads.name" />,
    type: 'input',
  },

  {
    param: 'username',
    label: <Trans i18nKey="subAccTableHeads.userName" />,
    type: 'input',
  },
  {
    Button: ResetButton,
    action: 'reset',
  },
  {
    param: 'note',
    label: <Trans i18nKey="subAccTableHeads.note" />,
    type: 'input',
  },
];

export const subAccountsParamsInit = {
  name: '',
  username: '',
  // password: '',
  note: '',
};
