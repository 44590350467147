import React from 'react';
import CardButtonRow from '../CardButtonRow';
import CardRow from '../CardRow';

const ItemCard = ({
  param, item, onButtonClick,
}) => {
  if (param.buttons && param.buttons.length) {
    return <CardButtonRow buttons={param.buttons} item={item} onClick={onButtonClick} />;
  }
  return <CardRow {...param} item={item} />;
};

export default ItemCard;
