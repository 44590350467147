import {
  Box, IconButton, Typography,
} from '@mui/material';
import React from 'react';
import { AppBar } from '../../index.style';
import BurgerIcon from './component/Burger';

function HeaderComponent({ open, onOpen, label }) {
  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#FFF',
        px: '20px',
        py: '10px',
        boxShadow: 'none',
        zIndex: 0,
      }}
    >
      <Box sx={{ display: 'flex', maxHeight: '40px', width: '100%' }}>
        <IconButton onClick={onOpen}>
          <BurgerIcon />
        </IconButton>
        <Typography
          noWrap
          color="#000"
          sx={{
            color: '#45464E', fontSize: '20px', fontWeight: 500, mr: 'auto', ml: 'auto',
          }}
        >
          {label}
        </Typography>
      </Box>
    </AppBar>
  );
}
export default HeaderComponent;
