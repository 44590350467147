import { NavLink } from 'react-router-dom';
import React from 'react';
import Box from '@mui/material/Box';
// import path from './index.config';

function PathItem({ open, path }) {
  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      { path.map((item) => (
        <Box key={item.link} sx={{ height: '56px', mt: '10px' }}>
          <NavLink
            to={item.link}
            style={{ textDecoration: 'none', height: '100%' }}
          >
            {({ isActive }) => (
              <Box sx={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                justifyContent: open ? 'start' : 'center',
                pl: open ? '30px' : '0',
                background: isActive ? '#5570F1' : '',
                borderRadius: '12px',
                minWidth: open ? '200px' : ' 56px',
              }}
              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: open ? '20px' : 0,
                }}
                >
                  <item.img color={isActive ? '#fff' : '#53545C'} isActive={isActive} />
                </Box>
                <span style={{
                  color: isActive ? '#fff' : '#53545C',
                  borderRadius: '5px',
                  display: open ? 'flex' : 'none',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                }}
                >
                  {item.text}
                </span>
              </Box>
            )}
          </NavLink>
        </Box>
      ))}
    </Box>
  );
}
export default PathItem;
