import { MButton } from '../Fab';
import { SvgTransfer } from '../Svg';

export const TransferButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <SvgTransfer color="#fff" />
    </MButton>
  );
};
