import { Box } from '@mui/material';
import React from 'react';

const CardButtonRow = ({ item, buttons, onClick }) => {
  const buttonEl = buttons.reduce((arr, { Button, action, isActive }) => {
    if (!isActive || isActive(item)) {
      arr.push(<Button
        key={`${action}_${item.id}`}
        onClick={() => onClick(action, item)}
      />);
    }
    return arr;
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', padding: '11px 10px 0px 10px' }}>
      <Box sx={{ marginRight: 'auto' }}>
        {buttonEl[0]}
      </Box>

      <Box>
        {buttonEl[1]}
      </Box>
    </Box>
  );
};

export default CardButtonRow;
