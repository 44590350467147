import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { apiSlice } from '@workspace/auth-board/src/store/api/apiSlice';
import Main from '@todcode/components/src/layout/Main';
import LayoutLogin from '@todcode/components/src/layout/Login';
import Buy from './scenes/Buy';
import LenderLogin from './scenes/Login';
import { logOut, selectAccountType, selectCurrentToken } from './features/auth/authSlice';
import { path, childrenPath } from './path/index.config';
import SubAccounts from './scenes/subAcoounts';
import PDFPage from './scenes/PDF';
import 'react-toastify/dist/ReactToastify.css';
import Intent from './scenes/Intent';
import History from './scenes/History';
import SetPasswordScene from './scenes/SetPassword';

function App() {
  // Hook
  const dispatch = useDispatch();

  // Selectors
  const token = useSelector(selectCurrentToken);
  const accountType = useSelector(selectAccountType);
  const isSomeQueryPending = useSelector((state) => Object.values(state.api.queries).some((query) => query.status === 'pending') || Object.values(state.api.mutations).some((query) => query.status === 'pending'));

  // Callback
  const handleLogout = () => {
    dispatch(logOut());
    dispatch(apiSlice.util.resetApiState());
  };

  return (
    <div className="App">
      <ToastContainer />
      <Backdrop open={isSomeQueryPending} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ height: '100%' }}>
        {token ? (
          <Box sx={{ height: '100%' }}>
            <Routes>
              <Route path="/" element={<Main path={accountType === 'parent' ? path : childrenPath} onLogout={handleLogout} />}>
                <Route path="/transactions" element={<Buy />} />
                <Route path="/requests" element={<Intent />} />
                {accountType === 'parent' && <Route path="/subAccounts" element={<SubAccounts />} />}
                <Route path="/transaction/:id" element={<PDFPage />} />
                {/* History */}
                <Route path="history" element={<History />} />
                <Route path="*" element={<Navigate to="/transactions" replace />} />
                <Route path="/" element={<Navigate to="/transactions" replace />} />
              </Route>
            </Routes>
          </Box>
        ) : (
          <Routes>
            <Route path="/" element={<LayoutLogin />}>
              <Route path="/login" element={<LenderLogin />} />
              <Route path="/setpassword/:passId" element={<SetPasswordScene />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
              <Route path="/" element={<Navigate to="/login" replace />} />
            </Route>
          </Routes>
        )}
      </Box>
    </div>
  );
}

export default App;
