export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: '10px',
  bgcolor: 'background.paper',
  border: '1px solid #5570F1',
  boxShadow: 24,
  p: 4,

  input: {
    display: 'flex',
    alignItems: 'center',
    my: 2,
    background: '#f5f5f5',
    borderRadius: '12px 0 0 12px',
  },
  title: {
    px: 2,
    mr: '10px',
    fontSize: '14px',
    overflow: 'hidden',
    // wordBreak: 'break-word',
    width: '100%',
    background: '#f5f5f5',
    height: '36px',
    borderRadius: '12px 0 0 12px',
    py: '8px',
  },
};
