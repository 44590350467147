export const labelConfig = {
  green: {
    background: '#32936F29',
    color: '#519C66',
  },
  yellow: {
    background: '#FFF2E2',
    color: '#1C1D22',
  },
  blue: {
    background: '#5570F129',
    color: '#5570F1',
  },
  red: {
    background: '#F57E7729',
    color: '#CC5F5F',
  },
  buy: {
    background: '#6CD276',
    color: '#DCFCE0',
  },
  transfer: {
    background: '#F1DF81',
    color: '#FFFFFF',
  },
  redeem: {
    background: '#86ECEC',
    color: '#EFFFFF',
  },
  requestBuy: {
    background: 'rgba(108, 210, 118, 0.46)',
    color: '#419C4A',
  },
  approveBuy: {
    background: '#6CD276',
    color: '#DCFCE0',
  },
  requestTransfer: {
    background: 'rgba(241, 223, 129, 0.46)',
    color: '#918546',
  },
  approveTransfer: {
    background: '#F1DF81',
    color: '#FFFFFF',
  },
  requestRedeem: {
    background: 'rgba(134, 236, 236, 0.49)',
    color: '#549292',
  },
  approveRedeem: {
    background: '#86ECEC',
    color: '#EFFFFF',
  },
  rejectTransfer: {
    background: '#E8BCBC',
    color: '#875F5F',
  },
  rejectRedeem: {
    background: '#E8BCBC',
    color: '#875F5F',
  },
  rejectBuy: {
    background: '#E8BCBC',
    color: '#875F5F',
  },
};
