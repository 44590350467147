import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelaaModal from '../../component/SelaaModal';

export const useModal = (onSubmit) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    open: false, data: null, title: '', response: null, subtitle: '', action: '', config: [], initialValues: {}, submitButton: 'Add', options: {}, readOnly: false,
  });

  const resetState = () => setState({
    open: false, data: null, title: '', response: null, subtitle: '', action: '', config: [], initialValues: {}, submitButton: 'Add', options: {}, readOnly: false,
  });

  const handleSubmit = (...args) => {
    resetState();

    onSubmit(...args);
  };

  return {
    modal: state.open
      && (
        <SelaaModal
          t={t}
          title={state.title}
          subTitle={state.subtitle}
          action={state.action}
          initialValues={state.initialValues}
          params={state.config}
          onSubmit={handleSubmit}
          onClose={() => resetState()}
          submitButton={state.submitButton}
          options={state.options}
          readOnly={state.readOnly}
          res={state.response}
        />
      ),
    show: ({
      data, title, subtitle, action, config, initialValues, submitButton, options, readOnly, response,
    }) => {
      setState({
        open: true, data, title, subtitle, action, config, initialValues, submitButton, options, readOnly, response,
      });
    },
  };
};
