import React from 'react';
import { SetPass } from '@todcode/components/src/scenes/SetPassword';
import { useDispatch } from 'react-redux';
import { useSetPassMutation } from '../../features/setPass/setPasswordApiSlice';
import { setCredentials } from '../../features/auth/authSlice';

const SetPasswordScene = () => {
  // Hook
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // RTK Query
  const [setPass] = useSetPassMutation();
  // Callback
  const handleSetPassword = async (values) => {
    try {
      const userData = await setPass(values).unwrap();
      dispatch(setCredentials({ ...userData }));
    } catch (err) {
      if (!err?.originalStatus) {
        console.log('No Server Response');
      } else if (err.originalStatus === 400) {
        console.log('Missing Username or Password');
      } else if (err.originalStatus === 401) {
        console.log('Unauthorized');
      } else {
        console.log('Index Failed');
      }
    }
  };

  return <SetPass setPassword={handleSetPassword} />;
};

export default SetPasswordScene;
