import { apiSlice } from '../../store/api/apiSlice';

export const redeemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    redeem: builder.mutation({
      query: (id) => ({
        url: `/operations/redeem/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Transactions', 'AvailableAmount'],
    }),
  }),
});

export const {
  useRedeemMutation,
} = redeemApiSlice;
