import {
  Box, Button, SwipeableDrawer, Typography,
} from '@mui/material';
import React from 'react';
import { Drawer, DrawerHeader } from '../../index.style';
import { SvgLogo, SvgLogout } from '../../../../component/Svg';
import PathItem from '../PathItem';
import logo from './img/selaa-logo.png';

const NavigationComponentMobile = ({
  open, path, onLogout, t, anchor, drawerBleeding, toggleDrawer,
}) => {
  return (
    <SwipeableDrawer
      swipeAreaWidth={drawerBleeding}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      anchor={anchor}
      open={open}
      sx={{ '& .MuiPaper-root': { width: '296px' } }}
    >
      <DrawerHeader sx={{
        justifyContent: 'center',
        alignItems: 'center',
        mt: '20px',
        px: { xs: '10px', md: '10px' },
      }}
      >
        <Box
          sx={{
            height: 'auto', width: '75px', cursor: 'pointer', display: 'flex',
          }}
        >
          <img src={logo} style={{ maxWidth: '100%' }} alt="logo" />
        </Box>
      </DrawerHeader>
      <Box>
        <PathItem open={open} path={path} />
      </Box>
      <Box sx={{
        mt: 'auto', display: 'flex', justifyContent: 'center', pb: '50px',
      }}
      >
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: open ? 'flex-start' : 'center',
            color: '#CC5F5F',
            fontFamily: 'Inter',
            fontSize: '14px',
            textTransform: 'none',
            minWidth: open ? '200px' : ' 56px',
          }}
          onClick={onLogout}
        >
          <SvgLogout />
          {' '}
          <Typography component="span" sx={{ display: open ? 'inline' : 'none', ml: '10px' }}>{t('button.logout')}</Typography>

        </Button>
      </Box>
    </SwipeableDrawer>
  );
};
export default NavigationComponentMobile;
