import React, { useState } from 'react';
import {
  Box, useMediaQuery,
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '@workspace/lenders/src/theme';
import HeaderComponent from './component/HeaderComponent';
import NavigationComponent from './component/NavigationComponent';
import NavigationComponentMobile from './component/NavigationComponentMobile';

const drawerBleeding = 5;
const Main = ({ path, label, onLogout }) => {
  // Hook
  const [open, setOpen] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  // Callback
  const handleOpen = () => {
    setOpen(!open);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      overflow: 'hidden',
    }}
    >
      <HeaderComponent open={open} onOpen={handleOpen} label={label} />
      {sm ? (
        <NavigationComponent
          open={open}
          path={path}
          onLogout={onLogout}
          t={t}
        />
      ) : (
        <NavigationComponentMobile
          t={t}
          open={open}
          path={path}
          onLogout={onLogout}
          anchor="left"
          toggleDrawer={toggleDrawer}
          drawerBleeding={drawerBleeding}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          minWidth: { xs: '100%', md: 'auto' },
          height: 'calc( 100%-50px )',
          overflowY: 'scroll',
          marginTop: '80px',
          marginBottom: '50px',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
export default Main;
