import { createTheme } from '@mui/material';
import { orange } from '@mui/material/colors';

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1920,
    },
  },

  typography: {
    fontFamily: [
      'Poppins',
    ].join(','),
  },

});

export default theme;
