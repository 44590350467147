import { apiSlice } from '../../store/api/apiSlice';

export const subApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubAccounts: builder.query({
      query: () => '/lenders',
      providesTags: ['Lenders'],
    }),
    createSubAccount: builder.mutation({
      query: (account) => ({
        url: '/lenders/create',
        method: 'POST',
        body: account,
      }),
      invalidatesTags: ['Lenders'],
      transformResponse: (response) => response.link,
    }),
  }),
});

export const {
  useGetSubAccountsQuery,
  useCreateSubAccountMutation,
} = subApiSlice;
