import {
  SvgHistory, SvgIntent, SvgOrder, SvgUsers,
} from '@todcode/components/src/component/Svg';
import { Trans } from 'react-i18next';

export const path = [
  {
    link: 'transactions',
    img: SvgOrder,
    text: <Trans i18nKey="path.transactions" />,
  },
  {
    link: 'requests',
    img: SvgIntent,
    text: <Trans i18nKey="path.intents" />,
  },
  {
    link: 'subAccounts',
    img: SvgUsers,
    text: <Trans i18nKey="path.subAccounts" />,
  },
  {
    link: 'history',
    img: SvgHistory,
    text: <Trans i18nKey="path.history" />,
  },
];

export const childrenPath = [
  {
    link: 'transactions',
    img: SvgOrder,
    text: <Trans i18nKey="path.transactions" />,
  },
  {
    link: 'requests',
    img: SvgIntent,
    text: <Trans i18nKey="path.intents" />,
  },
  {
    link: 'history',
    img: SvgHistory,
    text: <Trans i18nKey="path.history" />,
  },
];
