import React, { useMemo } from 'react';
import {
  Container, Box,
} from '@mui/material';
import {
  formatDateTime, SelaaCards, SelaaTable, useModal,
} from '@todcode/components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { historyInfoModalParams } from '@todcode/components/src/component/ModalConfig';
import ButtonCreateElement from '@todcode/components/src/component/ButtonCreateElement';
import { useBuyMutation } from '../../features/buy/buyApiSlice';
import { useGetTransactionsQuery, useGetAvailableAmountQuery } from '../../features/transactions/transactionsApiSlice';
import {
  buyInitialValues,
  buyModalParams,
  childrenTransactionsParams,
  transactionsParams,
  transferInitialValues,
  transferModalParams,
} from './index.config';
import { useTransferMutation } from '../../features/transfer/transferApiSlice';
import { useApproveMutation, useRejectMutation } from '../../features/approve/approveApiSlice';
import { useRedeemMutation } from '../../features/redeem/redeemApiSlice';
import { selectAccountType } from '../../features/auth/authSlice';
import AvailableAmount from './components/AvailableAmount';
import { useGetSubAccountsQuery } from '../../features/subAccounts/subaccApiSlice';

const Buy = () => {
  // Selector
  const accountType = useSelector(selectAccountType);

  // RTK Query
  const { data: transactionsRaw = [] } = useGetTransactionsQuery();
  const { data: amount } = useGetAvailableAmountQuery();
  const { data: lenders = [] } = useGetSubAccountsQuery();

  // Hook
  const transactions = useMemo(() => {
    return transactionsRaw.map((transaction) => {
      const lenderName = lenders.find((lender) => transaction.lenderId === lender.id)?.name || '';

      return { ...transaction, lenderName };
    });
  }, [transactionsRaw, lenders]);

  // RTK Query
  const [buy] = useBuyMutation();
  const [transfer] = useTransferMutation();
  const [approve] = useApproveMutation();
  const [reject] = useRejectMutation();
  const [redeem] = useRedeemMutation();
  const { t } = useTranslation();

  // Callback
  const handleBuy = async (values) => {
    await buy(values);
  };

  const handleTransfer = async (values) => {
    await transfer(values);
  };

  const handleRedeem = async (values) => {
    await redeem(values);
  };

  const handleApprove = async (id) => {
    await approve(id);
  };

  const handleReject = async (id) => {
    await reject(id);
  };

  const handleSubmit = (action, values) => {
    switch (action) {
      case 'buy':
        handleBuy(values);
        break;
      case 'transfer':
        handleTransfer(values);
        break;
      case 'redeem':
        handleRedeem(values.ownershipId);
        break;
      case 'approve':
        handleApprove(values.id);
        break;
      case 'reject':
        handleReject(values.id);
        break;
      default:
        break;
    }
  };

  // Hook
  const { modal, show } = useModal(handleSubmit);

  // Callback
  const handleBuyModal = () => {
    show({
      title: t('modal.title.buy'), action: 'buy', config: buyModalParams, initialValues: buyInitialValues, submitButton: t('button.buy'),
    });
  };

  const handleTransferModal = (data) => {
    show({
      title: t('modal.title.transfer'), action: 'transfer', config: transferModalParams, initialValues: { ...transferInitialValues, ownershipId: data.ownershipId }, submitButton: t('button.transfer'),
    });
  };

  const handleRedeemInit = (data) => {
    const dateTime = formatDateTime(data.dateTime);
    const transactionData = { ...data, dateTime };
    show({
      title: t('modal.title.redeem'), action: 'redeem', config: historyInfoModalParams, initialValues: transactionData, submitButton: t('button.redeem'),
    });
  };

  const handleApproveInit = (transaction) => {
    const dateTime = formatDateTime(transaction.dateTime);
    const transactionData = { ...transaction, dateTime };
    show({
      title: t('modal.title.confirm'), action: 'approve', config: historyInfoModalParams, initialValues: transactionData, submitButton: t('button.confirm'),
    });
  };

  const handleRejectInit = (transaction) => {
    const dateTime = formatDateTime(transaction.dateTime);
    const transactionData = { ...transaction, dateTime };
    show({
      title: t('modal.title.reject'), action: 'reject', config: historyInfoModalParams, initialValues: transactionData, submitButton: t('button.confirm'),
    });
  };

  const handleButtonClick = (action, data) => {
    switch (action) {
      case 'transfer':
        return handleTransferModal(data, 'create');
      case 'redeem':
        return handleRedeemInit(data);
      case 'approve':
        return handleApproveInit(data);
      case 'reject':
        return handleRejectInit(data);
      default:
        break;
    }
  };

  return (
    <Container>
      {modal}
      <ButtonCreateElement onClick={() => handleBuyModal(null, 'create')} t={t('button.buy')} />
      <AvailableAmount amount={amount?.amount} t={t} />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          title={t('titleTable.transaction')}
          data={transactions}
          params={accountType === 'parent' ? transactionsParams : childrenTransactionsParams}
          onButtonClick={handleButtonClick}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={transactions}
          params={accountType === 'parent' ? transactionsParams : childrenTransactionsParams}
          visibleFields={6}
          onButtonClick={handleButtonClick}
        />
      </Box>
    </Container>
  );
};

export default Buy;
