import { MenuItem, Typography } from '@mui/material';
import Input from '../Input';

const Select = ({
  options, selectKey, optionLabel, defaultValue, ...props
}) => (
  <Input
    {...props}
    select
    displayEmpty
  >
    <MenuItem value={defaultValue || 0}>
      <Typography component="span" sx={{ visibility: 'hidden' }}>None</Typography>
    </MenuItem>
    {options.map((option) => (
      <MenuItem key={option[selectKey]} value={option[selectKey]}>
        {option[optionLabel]}
      </MenuItem>
    ))}
  </Input>
);

export default Select;
