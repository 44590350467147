import { Trans } from 'react-i18next';

export const historyInfoModalParams = [
  {
    param: 'id',
    label: <Trans i18nKey="modal.fieldIntent.id" />,
    type: 'text',
  },
  {
    param: 'dateTime',
    label: <Trans i18nKey="modal.fieldIntent.date" />,
    type: 'text',
  },
  {
    param: 'amount',
    label: <Trans i18nKey="modal.fieldIntent.amount" />,
    type: 'text',
  },
];

export const historyTranAdminModalParams = [
  {
    param: 'ownershipId',
    label: <Trans i18nKey="modal.fieldTransaction.ownershipId" />,
    type: 'text',
  },
  {
    param: 'owner',
    label: <Trans i18nKey="modal.fieldTransaction.owner" />,
    type: 'text',
  },
  {
    param: 'walletName',
    label: <Trans i18nKey="modal.fieldTransaction.walletName" />,
    type: 'text',
  },
  {
    param: 'dateTime',
    label: <Trans i18nKey="modal.fieldTransaction.date" />,
    type: 'text',
  },
  {
    param: 'amount',
    label: <Trans i18nKey="modal.fieldTransaction.amount" />,
    type: 'text',
  },
];

export const historyTranModalParams = [
  {
    param: 'ownershipId',
    label: <Trans i18nKey="modal.fieldTransaction.ownershipId" />,
    type: 'text',
  },
  {
    param: 'owner',
    label: <Trans i18nKey="modal.fieldTransaction.owner" />,
    type: 'text',
  },
  {
    param: 'dateTime',
    label: <Trans i18nKey="modal.fieldTransaction.date" />,
    type: 'text',
  },
  {
    param: 'amount',
    label: <Trans i18nKey="modal.fieldTransaction.amount" />,
    type: 'text',
  },
];

export const transactionModal = [
  {
    type: 'title',
    label: <Trans i18nKey="modal.title.transactionInfo" />,
  },
  {
    type: 'text',
    label: <Trans i18nKey="modal.fieldTransaction.ownershipId" />,
    param: 'transaction.ownershipId',
  },
  {
    type: 'text',
    label: <Trans i18nKey="modal.fieldTransaction.date" />,
    param: 'transaction.dateTime',
  },
  {
    type: 'text',
    label: <Trans i18nKey="modal.fieldTransaction.owner" />,
    param: 'transaction.owner',
  },
  {
    param: 'transaction.amount',
    label: <Trans i18nKey="modal.fieldTransaction.amount" />,
    type: 'text',
  },
];

export const intentModal = [
  {
    type: 'title',
    label: <Trans i18nKey="modal.title.intentInfo" />,
  },
  {
    type: 'text',
    label: <Trans i18nKey="modal.fieldIntent.id" />,
    param: 'intent.id',
  },
  {
    type: 'text',
    label: <Trans i18nKey="modal.fieldIntent.date" />,
    param: 'intent.dateTime',
  },
  {
    type: 'text',
    label: <Trans i18nKey="modal.fieldIntent.amount" />,
    param: 'intent.amount',
  },

];
