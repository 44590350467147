import { MButton } from '../Fab';
import { SvgApprove } from '../Svg';

export const ApproveButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <SvgApprove color="#fff" />
    </MButton>
  );
};
