import React from 'react';
import { Grid, Typography } from '@mui/material';
import InputMask from 'react-input-mask';
import Input from '../Input';
import Checkbox from '../Checkbox';
import TextModal from '../TextModal';
import Select from '../Select';
import BodyCell from "../../../SelaaTable/components/BodyCell";
import {numberWithCommas} from "../../../../utils";

const get = require('lodash.get');

const FieldType = ({
  params, handleChange, handleBlur, values, errors, setFieldValue, options,
}) => {
  return (
    <Grid container sx={{ px: 3, pb: 3 }}>
      {
        params.map(({
          param,
          label,
          type,
          mask,
          placeholder,
          selectOptionsParam,
          selectKey,
          selectLabel,
          defaultValue,
        }, index) => {
          const valueLodash = get(values, param);

          const errorLodash = get(errors, param);
          let field;

          if (type === 'input' && mask) {
            field = (
              <InputMask
                key={index}
                mask={mask}
                name={param}
                onChange={handleChange}
                onBlur={handleBlur}
                value={valueLodash}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    fullWidth
                    label={label}
                    value={valueLodash}
                    error={errorLodash}
                    placeholder={placeholder}
                  />
                )}
              </InputMask>
            );
          } else if (type === 'input') {
            field = (
              <Input
                key={index}
                name={param}
                label={label}
                onChange={handleChange}
                onBlur={handleBlur}
                value={valueLodash}
                error={errorLodash}
              />
            );
          } else if (type === 'checkbox') {
            field = (
              <Checkbox
                sx={{ px: '10px' }}
                key={index}
                label={label}
                onChange={(e) => setFieldValue(param, e.target.checked)}
                checked={valueLodash}
              />
            );
          } else if (type === 'text') {
            field = (
              <TextModal key={index} label={label} values={valueLodash} />
            );
          } else if (type === 'title') {
            field = (
              <TextModal key={index} label={label} values={valueLodash} fs="18px" fw="500" />
            );
          } else if (type === 'select') {
            field = (
              <Select
                key={index}
                name={param}
                label={label}
                options={options[selectOptionsParam]}
                selectKey={selectKey}
                optionLabel={selectLabel}
                value={valueLodash}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errorLodash}
                defaultValue={defaultValue}
              />
            );
          }

          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={12}
              sx={{
                alignItems: 'center',
                display: 'flex',
                py: '5px',
                borderBottom: type === 'text' ? '1px solid  #E1E1E1' : '0px',
                '&:last-child': {
                  borderBottom: '0',
                },
                '&:nth-child(5)': {
                  borderBottom: '0',
                },
              }}
            >
              {field}
            </Grid>
          );
        })
            }
    </Grid>
  );
};

export default FieldType;
