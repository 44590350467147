import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
// pass the i18n instance to react-i18next.
  .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          admin: 'Admin',
          lender: 'Lender',
          error: {
            error: 'Something is wrong',
          },
          path: {
            wallets: 'Wallets',
            lenders: 'Lenders',
            transactions: 'Transactions',
            intents: 'Requests',
            subAccounts: 'Users',
            credentials: 'Credentials',
            history: 'History',
            reports: 'Reports',
          },
          button: {
            cancel: 'Cancel',
            submit: 'Add',
            createWallet: 'Create Wallet',
            topUpWallet: 'Top Up Wallet',
            createLenders: 'Create Lender',
            createCredentials: 'Create Credentials',
            createSubAcc: 'Create User',
            download: 'Download',
            mail: 'Send via email',
            buy: 'Buy',
            redeem: 'Redeem',
            delete: 'Delete',
            approve: 'Approve',
            reject: 'Reject',
            transfer: 'Transfer',
            confirm: 'Confirm',
            update: 'Update',
            logout: 'Logout',
          },
          availableAmount: 'Available Amount',
          modal: {
            title: {
              buy: 'Buy',
              transfer: 'Transfer',
              redeem: 'Confirm Redeem',
              reject: 'Reject Transaction',
              confirm: 'Approve Transaction',
              createW: 'Create Wallet',
              topupW: 'Top Up Wallet',
              updateW: 'Update Wallet',
              deleteW: 'Delete Wallet?',
              createL: 'Create Lender',
              updateL: 'Update Lender',
              deleteL: 'Delete Lender?',
              createC: 'Create Credentials',
              deleteC: 'Delete Credentials?',
              createSub: 'Create User',
              details: 'Details',
              intentInfo: 'Request Info',
              transactionInfo: 'Transaction Info',
            },
            fieldsTransfer: {
              amount: 'Amount',
              id: 'ID',
              lender: 'Lender',
              name: 'Name',
              civilId: 'Civil ID',
              phone: 'Phone',
              birthday: 'Birthdate',
            },
            fieldsWallet: {
              name: 'Name',
              bank: 'Bank',
              amount: 'Amount',
              note: 'Note',
              reducing: 'Reducing',
              default: 'Default',
              wallet: 'Wallet',
            },
            fieldsLender: {
              name: 'Name',
              username: 'Username',
              password: 'Password',
              note: 'Note',
              wallet: 'Wallet',
            },
            fieldsCredentials: {
              name: 'Name',
              lender: 'Lender',
              wallet: 'Wallet',
            },
            fieldIntent: {
              id: 'ID',
              date: 'Date:',
              amount: 'Amount:',
            },
            fieldTransaction: {
              ownershipId: 'ID:',
              date: 'Date:',
              owner: 'Owner:',
              walletName: 'Wallet',
              amount: 'Amount:',
            },
            fieldTransactionCreate: {
              id: 'Internal ID:',
              amount: 'Amount:',
              lender: 'Lender:',
            },
          },
          titleTable: {
            wallets: 'Wallets',
            lenders: 'Lenders',
            transaction: 'Transaction',
            transfer: 'Transfer',
            intent: 'Request',
            subAcc: 'Users',
            history: 'History',
            reports: 'Reports',
          },
          transactionTableHeads: {
            id: 'ID',
            vId: 'Number',
            eId: 'Lender ID',
            date: 'Date',
            amount: 'Amount',
            lender: 'Lender',
            type: 'Type',
            pdf: 'PDF',
            account: 'User',
            status: 'Status',
            actions: 'Actions',
          },
          walletTableHeads: {
            id: 'ID',
            name: 'Name',
            bank: 'Bank',
            note: 'Note',
            amount: 'Amount',
            locked: 'Locked',
            reducing: 'Reducing',
            default: 'Default',
            actions: 'Actions',
          },
          lendersTableHeads: {
            id: 'ID',
            name: 'Name',
            note: 'Note',
            wallet: 'Wallet',
            username: 'Username',
            password: 'Password',
            resetPass: 'Reset Password',
            actions: 'Actions',
            nameId: 'Parent ID',
          },
          credentialsTableHeads: {
            name: 'Name',
            lender: 'Lender',
            wallet: 'Wallet',
            id: 'Key',
            secret: 'Secret',
            actions: 'Actions',
          },
          intentTableHeads: {
            date: 'Date',
            amount: 'Amount',
            birthday: 'Birthdate',
            name: 'Name',
            phone: 'Phone',
            type: 'Type',
            civilId: 'Civil ID',
            nameLender: 'Lender',
            actions: 'Actions',
          },
          subAccTableHeads: {
            name: 'Name',
            note: 'Note',
            userName: 'Username',
            password: 'Password',
          },
          historyTableHeads: {
            actions: 'Action',
            type: 'Type',
            lender: 'User',
            amount: 'Amount',
            dateTime: 'Date',
            intentId: 'Request ID',
            ownershipId: 'Ownership ID',
          },
          historyFields: {
            approveTransfer: 'Approve Transfer',
            requestTransfer: 'Request Transfer',
            rejectTransfer: 'Reject Transfer',
            approveBuy: 'Approve Buy',
            requestBuy: 'Request Buy',
            rejectBuy: 'Reject Buy',
            approveRedeem: 'Approve Redeem',
            requestRedeem: 'Request Redeem',
            rejectRedeem: 'Reject Redeem',
          },
          login: {
            title: 'Welcome back!',
            subTitle: 'Login to your account',
            userName: 'Username',
            password: 'Password',
            button: 'Login',
          },
          setpassword: {
            title: 'Set Password',
            password: 'Password',
            confirmpassword: 'Confirm Password',
          },
        },
      },
    },
  });

export default i18n;
