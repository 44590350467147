import {Grid, Typography} from '@mui/material';
import React from 'react';
import {numberWithCommas} from "../../../../utils";

const TextModal = ({
                       label, values, fs, fw,
                   }) => {
    let val
    if (typeof values === 'number') {
        val = (
            numberWithCommas(values)
        )
    }else{
        val = values
    }
    return (
        <Grid
            container
            sx={{
                py: fs ? '10px' : 0,
            }}
        >
            <Grid item xs={6}>
                <Typography
                    component="span"
                    sx={{
                        color: fs ? '#000000' : '#A6A6A6',
                        fontSize: fs || '14px',
                        fontWeight: fw,
                    }}
                >
                    {label}
                </Typography>
            </Grid>
            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'right',
                }}
            >
                <Typography
                    component="span"
                    sx={{
                        color: '#A6A6A6',
                        fontSize: fs || '14px',
                        lineHeight: '19px',
                        textAlign: 'right',
                    }}
                >
                    {val}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default TextModal;
