import { InputAdornment } from '@mui/material';
import React from 'react';
import CssTextField from '../../../Input/index.styled';

const Input = (props) => (
  <CssTextField
    sx={{
      fontSize: '16px',
      p: 1,
    }}
    fullWidth
    InputProps={{
      startAdornment: <InputAdornment position="start" />,
      disableUnderline: true,
    }}
    variant="filled"
    {...props}
  />
);

export default Input;
