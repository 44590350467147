import { apiSlice } from '../../store/api/apiSlice';

export const transactionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: () => '/transactions',
      providesTags: ['Transactions'],
    }),
    getTransaction: builder.query({
      query: (id) => `/transactions/single/${id}`,
      providesTags: ['Transaction'],
    }),
    getAvailableAmount: builder.query({
      query: () => 'lenders/available',
      providesTags: ['AvailableAmount'],
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetTransactionQuery,
  useLazyGetTransactionQuery,
  useGetAvailableAmountQuery,
} = transactionsApiSlice;
