import { FormControlLabel, Checkbox as MUICheckbox, Box } from '@mui/material';

const Icon = ({ children }) => (
  <Box sx={{
    display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px', height: '24px', borderRadius: '8px', border: '1px solid #6D7DCD',
  }}
  >
    {children}
  </Box>
);

const CheckedIcon = () => (
  <Icon>
    <Box sx={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20px', height: '20px', background: '#5570F1', borderRadius: '6px',
    }}
    >
      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 1L3.5 6.5L1 4" stroke="#B0CAD9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Box>
  </Icon>
);

const Checkbox = (props) => (
  <FormControlLabel
    control={(
      <MUICheckbox
        icon={<Icon />}
        checkedIcon={<CheckedIcon />}
      />
    )}
    sx={{
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Inter',
        fontSize: '14px',
        color: '#2B2F32',
      },
    }}
    {...props}

  />
);

export default Checkbox;
