import { apiSlice } from '../../store/api/apiSlice';

export const transferApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    transfer: builder.mutation({
      query: (transfer) => ({
        url: '/operations/transfer',
        method: 'POST',
        body: transfer,
      }),
      invalidatesTags: ['Transactions', 'AvailableAmount'],
    }),
  }),
});

export const {
  useTransferMutation,
} = transferApiSlice;
