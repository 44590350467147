import { ApproveButton } from '@todcode/components/src/component/AproveButton';
import { RejectButton } from '@todcode/components/src/component/RejectButton';
import { Trans } from 'react-i18next';
import { capitalize, formatDate, formatDateTime } from '@todcode/components';

export const intentParams = [
  {
    param: 'dateTime',
    label: <Trans i18nKey="intentTableHeads.date" />,
    align: 'center',
    transform: formatDateTime,
  },
  {
    param: 'type',
    label: <Trans i18nKey="intentTableHeads.type" />,
    transform: capitalize,
  },
  {
    param: 'amount',
    label: <Trans i18nKey="intentTableHeads.amount" />,
  },
  {
    param: 'birthday',
    label: <Trans i18nKey="intentTableHeads.birthday" />,
    transform: formatDate,
  },
  {
    param: 'name',
    label: <Trans i18nKey="intentTableHeads.name" />,
  },
  {
    param: 'phone',
    label: <Trans i18nKey="intentTableHeads.phone" />,
  },
  {
    param: 'civilId',
    label: <Trans i18nKey="intentTableHeads.civilId" />,
  },

];

export const intentParentParams = [
  {
    label: <Trans i18nKey="intentTableHeads.nameLender" />,
    param: 'lenderName',
  },
  ...intentParams,
  {
    label: <Trans i18nKey="intentTableHeads.actions" />,
    align: 'center',
    buttons: [
      {
        Button: ApproveButton,
        action: 'approve',
      },
      {
        Button: RejectButton,
        action: 'reject',
      },
    ],
  },
];
