import { Box } from '@mui/material';
import React from 'react';

const OpenButton = ({ onClick }) => (
  <Box onClick={onClick} sx={{ width: '100%' }}>
    <div style={{
      display: 'flex', padding: '16px 0px 0px 151px',
    }}
    >
      <div style={{ width: '18px', border: '1.5px solid #DEDEDE', height: '0px' }} />
    </div>
    <div style={{
      display: 'flex', padding: '5px 0px 5px 155px',
    }}
    >
      <div style={{ width: '9px', border: '1.5px solid #DEDEDE' }} />
    </div>
  </Box>
);

export default OpenButton;
