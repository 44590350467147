import { Box } from '@mui/material';
import Card from './component/Card';

export const SelaaCards = ({
  data, params, visibleFields, onButtonClick, keyParam = 'id',
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {
        data.map((item) => (
          <Card
            key={item[keyParam]}
            data={item}
            params={params}
            visibleFields={visibleFields}
            onButtonClick={onButtonClick}
          />
        ))
      }
    </Box>
  );
};
