import { TableCell } from '@mui/material';

const BodyCell = ({ sx, ...props }) => (
  <TableCell
    {...props}
    sx={{
      borderBottom: 'none',
      color: '#6E7079',
      fontSize: '14px',
      ...sx,
    }}
  />
);

export default BodyCell;
