export const formatDateTime = (value) => {
    if (!value) return value;

    const [date, timeZ] = value.split('T');
    const time = timeZ.split('+')[0];

    return `${date} ${time}`;
};

export const formatDate = (value) => {
    if (!value) return value;

    const [date] = value.split('T');

    return date;
};
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
