import {
  Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HeaderCell from './components/HeaderCell';
import BodyCell from './components/BodyCell';
import Label from '../Label';
import { capitalize, numberWithCommas } from '../../utils';

export const SelaaTable = ({
  data, title, params, onButtonClick, t,
}) => {
  return (
    <Box sx={{ background: '#fff', borderRadius: '12px', padding: '20px' }}>
      <Typography sx={{
        fontFamily: 'Inter', fontSize: '16px', fontWeight: 500, color: '#45464E', mb: '25px',
      }}
      >
        {title}
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: 'none', borderRadius: 0 }}>
        <Table>
          <TableHead sx={{ borderTop: '1px solid #E1E2E9' }}>
            <TableRow>
              {params.map(({ label, align }, idx) => <HeaderCell key={idx} align={align || 'center'}>{label}</HeaderCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(data).map((item) => {
              return (
                <TableRow key={item.id}>
                  {
                        params.map(({
                          label, param, align, buttons, type, transform, checkbox, mix,
                        }) => {
                          if (type === 'link') {
                            return (
                              <BodyCell key={param} align={align || 'center'}>
                                <Link to={`/transaction/${item.ownershipId}`}>File</Link>
                              </BodyCell>
                            );
                          }
                          if (mix && item.type === 'transfer') {
                            if (item.action === 'done') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={capitalize(item.type)} color="transfer" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'approve') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.approveTransfer')} color="approveTransfer" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'create') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.requestTransfer')} color="requestTransfer" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'reject') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.rejectTransfer')} color="rejectTransfer" />
                                </BodyCell>
                              );
                            }
                          }
                          if (mix && item.type === 'buy') {
                            if (item.action === 'done') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={capitalize(item.type)} color="buy" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'approve') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.approveBuy')} color="approveBuy" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'create') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.requestBuy')} color="requestBuy" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'reject') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.rejectBuy')} color="rejectBuy" />
                                </BodyCell>
                              );
                            }
                          }
                          if (mix && item.type === 'redeem') {
                            if (item.action === 'done') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={capitalize(item.type)} color="redeem" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'approve') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.approveRedeem')} color="approveRedeem" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'create') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.requestRedeem')} color="requestRedeem" />
                                </BodyCell>
                              );
                            }
                            if (item.action === 'reject') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={t('historyFields.rejectRedeem')} color="rejectRedeem" />
                                </BodyCell>
                              );
                            }
                          }
                          if (mix && item.type === 'topup') {
                            if (item.action === 'topup') {
                              return (
                                <BodyCell align={align || 'center'}>
                                  <Label value={capitalize(item.type)} color="blue" />
                                </BodyCell>
                              );
                            }
                            
                          }
                          if (buttons && buttons.length) {
                            const buttonsEl = buttons.reduce((arr, { Button, action, isActive }) => {
                              if (!isActive || isActive(item)) {
                                arr.push(<Button
                                  key={`${action}_${item.id}`}
                                  onClick={() => onButtonClick(action, item)}
                                />);
                              }
                              return arr;
                            }, []);

                            return (
                              <BodyCell key={`${item.id}_buttons`} sx={{ display: 'flex', justifyContent: 'center' }} align={align || 'center'}>
                                <Box sx={{ mr: '10px' }}>
                                  {buttonsEl[0]}
                                </Box>
                                <Box sx={{ mr: '10px' }}>
                                  { buttonsEl[1]}
                                </Box>
                                <Box>
                                  { buttonsEl[2]}
                                </Box>
                              </BodyCell>
                            );
                          }

                          let value = item[param];

                          if (typeof value === 'number') {
                            return (
                              <BodyCell align={align || 'center'}>
                                { value === item.id ? value : numberWithCommas(value)}
                              </BodyCell>
                            );
                          }
                          if (transform) {
                            value = transform(value, item);
                          }

                          return (
                            <BodyCell key={param} align={align || 'center'}>
                              { value}
                            </BodyCell>
                          );
                        })
                    }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
