import React, { useState, useRef } from 'react';
import {
  Box, Button, IconButton, InputAdornment, Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import logo from '../../layout/Main/component/NavigationComponent/img/selaa-logo.png';
import CssTextField from '../../component/Input/index.styled';
import { ReactComponent as Password } from './img/Lock.svg';
import { ReactComponent as Person } from './img/Person.svg';
import { ValidationSchema } from './PassValidationSchemas';

export const SetPass = ({ setPassword }) => {
// https://selaaapp.web.app/setpassword?passwordId=tIidP9F4sugjGBh
  const firstInput = useRef();
  const secondInput = useRef();
  const buttonRef = useRef();


  const { passId } = useParams();
  const location = useLocation();
  
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get('username');


  // Hook
  const [valuesPass, setValuesPass] = useState({
    password: '',
    showPassword: false,
    showPassConfirm: false,
  });
  const { t } = useTranslation();

  // Callback
  const handleSetPass = (values) => setPassword({ password: values.password, passwordId: values.passwordId });

  const handleClickShowPassword = () => {
    setValuesPass({
      ...valuesPass,
      showPassword: !valuesPass.showPassword,
    });
  };
  const handleClickShowPasswordConfirm = () => {
    setValuesPass({
      ...valuesPass,
      showPassConfirm: !valuesPass.showPassConfirm,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: '12px',
      bgcolor: '#fff',
      p: '34px',
      width: '500px',
      maxWidth: '95%',
    }}
    >
      <Box sx={{ pt: { xs: '10px', md: '15px' }, pb: { xs: '15px', md: '20px', lg: '35px' } }}>
        <img src={logo} alt="" style={{ width: '120px' }} />
      </Box>
      <Box sx={{ pb: '28px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{`Set Password`}</Typography>
      </Box>

      <Box sx={{
        display: 'flex', flexDirection: 'column', pb: '12px', width: '100%',
      }}
      >
        <Formik
         validate={false}
         validateOnChange={false}
          initialValues={{
            password: '',
            passwordConfirmation: '',
            passwordId: passId,
          }}
          onSubmit={handleSetPass}
          validationSchema={ValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
          }) => (
            <Form>
              <Box sx={{ width: '100%' }}>
                <CssTextField
                  sx={{ pb: '30px' }}
                  placeholder={'Username'}
                  name="username"
                  type={'text'}
                  value={username}
                  fullWidth
                  InputProps={{readOnly: true, disableUnderline: true,  
                    startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),}}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <CssTextField
                  inputRef={firstInput}
                  sx={{ pb: '30px' }}
                  placeholder={t('setpassword.password')}
                  name="password"
                  type={valuesPass.showPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  value={values.password}
                  fullWidth
                  error={errors.password && touched.password && errors.password}
                  helperText={errors.password && touched.password && errors.password}
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      e.preventDefault();
                      secondInput.current.focus();
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {valuesPass.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box sx={{ width: '100%' }}>
                <CssTextField
                  inputRef={secondInput}
                  name="passwordConfirmation"
                  type={valuesPass.showPassConfirm ? 'text' : 'password'}
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  fullWidth
                  placeholder={t('setpassword.confirmpassword')}
                  error={errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation}
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirm}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {valuesPass.showPassConfirm ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
              inputRef={buttonRef}
               sx={{
                display: 'flex', justifyContent: 'flex-end', width: '100%', pb: { xs: '15px', md: '30px', lg: '48px' },
              }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {' '}
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    height: '58px', bgcolor: '#5570F1', borderRadius: '12px', textTransform: 'none', width: '180px',
                  }}
                >
                  <Typography sx={{ fontFamily: 'Inter', fontSize: '20px' }}>
                    Save
                  </Typography>
                </Button>
              </Box>
            </Form>
          )}

        </Formik>

      </Box>

    </Box>
  );
};
