import React, { useState } from 'react';
import {
  Container, Box,
} from '@mui/material';
import { SelaaCards, SelaaTable, useModal } from '@todcode/components';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { useTranslation } from 'react-i18next';
import ButtonCreateElement from '@todcode/components/src/component/ButtonCreateElement';
import ModalCopyLink from '@todcode/components/src/component/ModalCopyLink';
import { subAccountsParams, subAccountsParamsInit } from './index.config';
import { useCreateSubAccountMutation, useGetSubAccountsQuery } from '../../features/subAccounts/subaccApiSlice';

const SubAccounts = () => {
  // RTK Query
  const { data: account = [] } = useGetSubAccountsQuery();
  const [createAccount, { data }] = useCreateSubAccountMutation();
  const [open, setOpen] = useState(false);
  // Callback
  const handleSubCreate = async (values) => {
    const resData = await createAccount(values);
    if (!resData.error) {
      await setOpen(true);
    }
  };

  const handleSubmit = (action, values) => {
    switch (action) {
      case 'create':
        handleSubCreate(values);
        break;
      default:
        break;
    }
  };

  // Hook
  const { modal, show } = useModal(handleSubmit);
  const { t } = useTranslation();

  // Callback
  const handleClose = () => setOpen(false);

  const handleSubModal = () => {
    show({
      title: t('modal.title.createL'), action: 'create', config: subAccountsParams, initialValues: subAccountsParamsInit, submitButton: t('button.submit'),
    });
  };

  return (
    <Container>
      {modal}
      <ButtonCreateElement icon={<LocalMallIcon />} onClick={() => handleSubModal(null, 'create')} t={t('button.createSubAcc')} />
      <ModalCopyLink open={open} data={data} handleClose={handleClose} />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <SelaaTable
          title={t('titleTable.subAcc')}
          data={account}
          params={subAccountsParams}
        />
      </Box>
      <Box xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <SelaaCards
          data={account}
          params={subAccountsParams}
          visibleFields={4}
        />
      </Box>
    </Container>
  );
};

export default SubAccounts;
