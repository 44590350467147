import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MButton } from '../Fab';

export const MoreButtonIntent = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <MoreHorizIcon sx={{ color: '#fff' }} />
    </MButton>
  );
};
