import { apiSlice } from '../../store/api/apiSlice';

export const setPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    setPass: builder.mutation({
      query: (body) => ({
        url: '/lenders/password/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Transactions', 'AvailableAmount', 'Intents'],
    }),
  }),
});

export const {
  useSetPassMutation,
} = setPasswordApiSlice;
