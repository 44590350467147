import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import logo from '../Main/component/NavigationComponent/img/selaa-logo.png';

const LayoutLogin = () => {
  return (
    <Box sx={{ height: '100%' }}>

      <Box sx={{
        display: 'flex',
        position: 'relative',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%',
        height: { xs: '45px', md: '50px', lg: '76px' },
        bgcolor: '#fff',
        px: '80px',
        py: '5px',
      }}
      >
        <Box sx={{ width: { xs: '60px', md: '90px', lg: '100px' } }}>
          <img src={logo} style={{ width: '100%' }} alt="logo" />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
      }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default LayoutLogin;
