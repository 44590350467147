import { MButton } from '../Fab';
import { SvgRedeeem } from '../Svg';

export const RedeemButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <SvgRedeeem color="#fff" />
    </MButton>
  );
};
