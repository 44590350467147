import React from 'react';

export const SvgClose = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SvgLogout = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M0 4.447C0 1.996 2.03024 0 4.52453 0H9.48564C11.9748 0 14 1.99 14 4.437V15.553C14 18.005 11.9698 20 9.47445 20H4.51537C2.02515 20 0 18.01 0 15.563V14.623V4.447Z" fill="#CC5F5F" />
    <path d="M19.7792 9.4548L16.9334 6.5458C16.6393 6.2458 16.166 6.2458 15.8728 6.5478C15.5807 6.8498 15.5816 7.3368 15.8748 7.6368L17.434 9.2298H15.9391H7.54875C7.13483 9.2298 6.79883 9.5748 6.79883 9.9998C6.79883 10.4258 7.13483 10.7698 7.54875 10.7698H17.434L15.8748 12.3628C15.5816 12.6628 15.5807 13.1498 15.8728 13.4518C16.0199 13.6028 16.2118 13.6788 16.4046 13.6788C16.5955 13.6788 16.7873 13.6028 16.9334 13.4538L19.7792 10.5458C19.9204 10.4008 20.0003 10.2048 20.0003 9.9998C20.0003 9.7958 19.9204 9.5998 19.7792 9.4548Z" fill="#CC5F5F" />
  </svg>
);

export const SvgDownload = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1221 13.436L10.1221 1.39502" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.0381 10.5083L10.1221 13.4363L7.20609 10.5083" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.7551 6.12793H15.6881C17.7231 6.12793 19.3721 7.77693 19.3721 9.81293V14.6969C19.3721 16.7269 17.7271 18.3719 15.6971 18.3719L4.55707 18.3719C2.52207 18.3719 0.87207 16.7219 0.87207 14.6869V9.80193C0.87207 7.77293 2.51807 6.12793 4.54707 6.12793H5.48907" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export function SvgLogo({ height, width }) {
  return (
    <svg width={width} height={height} viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8819 8.88882C21.0092 9.14798 21.0933 9.42552 21.131 9.71084L21.8271 20.0606L22.1726 25.2627C22.1762 25.7976 22.2601 26.3292 22.4217 26.8401C22.839 27.8314 23.8429 28.4614 24.9351 28.4175L41.5783 27.3289C42.299 27.317 42.995 27.5865 43.5131 28.0782C43.9449 28.4879 44.2236 29.0239 44.3114 29.6004L44.3409 29.9505C43.6522 39.4872 36.6479 47.4416 27.1309 49.495C17.6139 51.5483 7.85466 47.2107 3.15177 38.8372C1.79597 36.4045 0.949126 33.7306 0.660955 30.9724C0.540571 30.1559 0.487564 29.3312 0.502448 28.5064C0.487585 18.2818 7.76868 9.44239 17.9608 7.31141C19.1875 7.12041 20.3901 7.7698 20.8819 8.88882Z"
        fill="#97A5EB"
      />
      <path
        opacity="0.4"
        d="M27.6749 0.00204796C39.0746 0.292066 48.6557 8.48947 50.4999 19.5307L50.4823 19.6122L50.432 19.7307L50.439 20.0559C50.4129 20.4868 50.2465 20.9014 49.9598 21.2362C49.6612 21.585 49.2532 21.8226 48.8039 21.9148L48.5299 21.9524L29.3279 23.1965C28.6892 23.2595 28.0533 23.0536 27.5783 22.6299C27.1825 22.2768 26.9294 21.8002 26.8579 21.2867L25.5691 2.11266C25.5466 2.04782 25.5466 1.97754 25.5691 1.9127C25.5867 1.38418 25.8193 0.8846 26.215 0.525575C26.6107 0.166549 27.1365 -0.0220009 27.6749 0.00204796Z"
        fill="#FFCC91"
      />
    </svg>
  );
}

export function SvgLock({ height, width }) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4232 7.4478V5.3008C13.4232 2.7878 11.3852 0.7498 8.87225 0.7498C6.35925 0.7388 4.31325 2.7668 4.30225 5.2808V5.3008V7.4478"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.683 19.2496H5.042C2.948 19.2496 1.25 17.5526 1.25 15.4576V11.1686C1.25 9.0736 2.948 7.3766 5.042 7.3766H12.683C14.777 7.3766 16.475 9.0736 16.475 11.1686V15.4576C16.475 17.5526 14.777 19.2496 12.683 19.2496Z"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.86279 12.2027V14.4237"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SvgMessage({ height, width }) {
  return (
    <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9028 6.85114L12.4596 10.4642C11.6201 11.1302 10.4389 11.1302 9.59944 10.4642L5.11865 6.85114"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SvgProfile({ height, width }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98493 13.3462C4.11731 13.3462 0.814453 13.931 0.814453 16.2729C0.814453 18.6148 4.09636 19.2205 7.98493 19.2205C11.8525 19.2205 15.1545 18.6348 15.1545 16.2938C15.1545 13.9529 11.8735 13.3462 7.98493 13.3462Z"
        stroke="#6E7079"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98489 10.0059C10.523 10.0059 12.5801 7.94781 12.5801 5.40971C12.5801 2.87162 10.523 0.814476 7.98489 0.814476C5.44679 0.814476 3.3887 2.87162 3.3887 5.40971C3.38013 7.93924 5.42394 9.99733 7.95251 10.0059H7.98489Z"
        stroke="#6E7079"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

// Navbar icons
export function SvgCategory({ isActive }) {
  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isActive ? (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 4.5C1 1.87479 1.02811 1 4.5 1C7.97189 1 8 1.87479 8 4.5C8 7.12521 8.01107 8 4.5 8C0.988927 8 1 7.12521 1 4.5Z"
            stroke="#53545C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4.5C12 1.87479 12.0281 1 15.5 1C18.9719 1 19 1.87479 19 4.5C19 7.12521 19.0111 8 15.5 8C11.9889 8 12 7.12521 12 4.5Z"
            stroke="#53545C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15.5C1 12.8748 1.02811 12 4.5 12C7.97189 12 8 12.8748 8 15.5C8 18.1252 8.01107 19 4.5 19C0.988927 19 1 18.1252 1 15.5Z"
            stroke="#53545C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 15.5C12 12.8748 12.0281 12 15.5 12C18.9719 12 19 12.8748 19 15.5C19 18.1252 19.0111 19 15.5 19C11.9889 19 12 18.1252 12 15.5Z"
            stroke="#53545C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

      ) : (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity={isActive ? '0.4' : '0'}
            d="M14.0756 0H17.4616C18.8639 0 20.0001 1.14585 20.0001 2.55996V5.97452C20.0001 7.38864 18.8639 8.53449 17.4616 8.53449H14.0756C12.6734 8.53449 11.5371 7.38864 11.5371 5.97452V2.55996C11.5371 1.14585 12.6734 0 14.0756 0Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.53852 0H5.92449C7.32676 0 8.46301 1.14585 8.46301 2.55996V5.97452C8.46301 7.38864 7.32676 8.53449 5.92449 8.53449H2.53852C1.13626 8.53449 0 7.38864 0 5.97452V2.55996C0 1.14585 1.13626 0 2.53852 0ZM2.53852 11.4655H5.92449C7.32676 11.4655 8.46301 12.6114 8.46301 14.0255V17.44C8.46301 18.8532 7.32676 20 5.92449 20H2.53852C1.13626 20 0 18.8532 0 17.44V14.0255C0 12.6114 1.13626 11.4655 2.53852 11.4655ZM17.4615 11.4655H14.0755C12.6732 11.4655 11.537 12.6114 11.537 14.0255V17.44C11.537 18.8532 12.6732 20 14.0755 20H17.4615C18.8637 20 20 18.8532 20 17.44V14.0255C20 12.6114 18.8637 11.4655 17.4615 11.4655Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}

export function SvgOrder({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 1V23" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export function SvgHistory({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={color}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" />
    </svg>
  );
}

export function SvgIntent({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill={color}>
      <g><rect fill="none" height="24" width="24" /></g>
      <g>
        <g>
          <rect height="2" width="11" x="3" y="10" />
          <rect height="2" width="11" x="3" y="6" />
          <rect height="2" width="7" x="3" y="14" />
          <polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34" />
        </g>
      </g>
    </svg>
  );
}

export function SvgUsers({ color }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.59102 13.207C11.28 13.207 14.433 13.766 14.433 15.999C14.433 18.232 11.301 18.807 7.59102 18.807C3.90102 18.807 0.749023 18.253 0.749023 16.019C0.749023 13.785 3.88002 13.207 7.59102 13.207Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.59108 10.02C5.16908 10.02 3.20508 8.057 3.20508 5.635C3.20508 3.213 5.16908 1.25 7.59108 1.25C10.0121 1.25 11.9761 3.213 11.9761 5.635C11.9851 8.048 10.0351 10.011 7.62208 10.02H7.59108Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4824 8.88177C16.0834 8.65677 17.3164 7.28277 17.3194 5.61977C17.3194 3.98077 16.1244 2.62077 14.5574 2.36377"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5947 12.7324C18.1457 12.9634 19.2287 13.5074 19.2287 14.6274C19.2287 15.3984 18.7187 15.8984 17.8947 16.2114"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SvgAlert() {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4749 8.70428C13.8662 7.99341 13.5896 7.37738 13.5896 6.3308V5.97495C13.5896 4.61112 13.2757 3.73239 12.5932 2.85367C11.5414 1.489 9.77064 0.666504 8.03718 0.666504H7.96347C6.26645 0.666504 4.55121 1.45123 3.48115 2.76051C2.76143 3.65686 2.4111 4.57335 2.4111 5.97495V6.3308C2.4111 7.37738 2.15269 7.99341 1.52573 8.70428C1.06441 9.22799 0.916992 9.9011 0.916992 10.6296C0.916992 11.3589 1.15635 12.0497 1.63671 12.6111C2.26367 13.2842 3.14904 13.7139 4.05345 13.7886C5.36287 13.938 6.67228 13.9943 8.00074 13.9943C9.32837 13.9943 10.6378 13.9003 11.948 13.7886C12.8516 13.7139 13.737 13.2842 14.3639 12.6111C14.8435 12.0497 15.0837 11.3589 15.0837 10.6296C15.0837 9.9011 14.9362 9.22799 14.4749 8.70428Z"
        fill="#5570F1"
      />
      <path
        opacity="0.4"
        d="M9.67445 15.0233C9.25786 14.9343 6.71937 14.9343 6.30278 15.0233C5.94664 15.1055 5.56152 15.2969 5.56152 15.7165C5.58223 16.1168 5.81661 16.4702 6.14128 16.6943L6.14045 16.6951C6.56035 17.0224 7.05314 17.2306 7.56912 17.3053C7.84409 17.343 8.12403 17.3414 8.40894 17.3053C8.92409 17.2306 9.41688 17.0224 9.83678 16.6951L9.83596 16.6943C10.1606 16.4702 10.395 16.1168 10.4157 15.7165C10.4157 15.2969 10.0306 15.1055 9.67445 15.0233Z"
        fill="#5570F1"
      />
    </svg>
  );
}

export const SvgWallet = ({ color }) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6389 12.3957H16.5906C15.1042 12.3948 13.8993 11.1909 13.8984 9.70446C13.8984 8.21801 15.1042 7.01409 16.5906 7.01318H20.6389" stroke={color} style={{ transition: 'stroke 0.2s ease-out 0.2s' }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.049 9.6427H16.7373" stroke={color} style={{ transition: 'stroke 0.2s ease-out 0.2s' }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.74766 1H15.3911C18.2892 1 20.6388 3.34951 20.6388 6.24766V13.4247C20.6388 16.3229 18.2892 18.6724 15.3911 18.6724H6.74766C3.84951 18.6724 1.5 16.3229 1.5 13.4247V6.24766C1.5 3.34951 3.84951 1 6.74766 1Z" stroke={color} style={{ transition: 'stroke 0.2s ease-out 0.2s' }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.03516 5.5382H11.4341" stroke={color} style={{ transition: 'stroke 0.2s ease-out 0.2s' }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export const SvgTransfer = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 23L3 19L7 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 13V15C21 16.0609 20.5786 17.0783 19.8284 17.8284C19.0783 18.5786 18.0609 19 17 19H3" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 1L21 5L17 9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 11V9C3 7.93913 3.42143 6.92172 4.17157 6.17157C4.92172 5.42143 5.93913 5 7 5H21" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export const SvgApprove = ({ color }) => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 1L6 12L1 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export const SvgRedeem = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.125 10.7949H16.898" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z" fill="#130F26" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z" fill="#130F26" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export const SvgCredentials = ({ color }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5003 6.5L18.0003 3M20.0003 1L18.0003 3L20.0003 1ZM10.3903 10.61C10.9066 11.1195 11.3171 11.726 11.598 12.3948C11.879 13.0635 12.0249 13.7813 12.0273 14.5066C12.0297 15.232 11.8887 15.9507 11.6122 16.6213C11.3357 17.2919 10.9293 17.9012 10.4164 18.4141C9.90351 18.9271 9.2942 19.3334 8.62358 19.6099C7.95296 19.8864 7.23427 20.0275 6.50891 20.025C5.78354 20.0226 5.06582 19.8767 4.39707 19.5958C3.72831 19.3148 3.12174 18.9043 2.61227 18.388C1.6104 17.3507 1.05604 15.9614 1.06857 14.5193C1.0811 13.0772 1.65953 11.6977 2.67927 10.678C3.69902 9.65825 5.07849 9.07982 6.52057 9.06729C7.96265 9.05476 9.35196 9.60913 10.3893 10.611L10.3903 10.61ZM10.3903 10.61L14.5003 6.5L10.3903 10.61ZM14.5003 6.5L17.5003 9.5L21.0003 6L18.0003 3L14.5003 6.5Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export const SvgRedeeem = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Iconly/Light/Paper Negative">
        <g id="Paper Negative">
          <path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M14.7366 2.76176H8.08455C6.00455 2.75276 4.29955 4.41076 4.25055 6.49076V17.3398C4.21555 19.3898 5.84855 21.0808 7.89955 21.1168C7.96055 21.1168 8.02255 21.1168 8.08455 21.1148H16.0726C18.1416 21.0938 19.8056 19.4088 19.8026 17.3398V8.03976L14.7366 2.76176Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path id="Stroke 3" d="M14.4736 2.75V5.659C14.4736 7.079 15.6226 8.23 17.0426 8.234H19.7966" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path id="Stroke 5" d="M14.2926 13.7471H9.39258" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
    </svg>
  );
};

export const SvgReports = ({ color }) => {
  return (
    <svg width="24px"  height="24px"  viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Iconly/Light/Document" stroke={color}  strokeWidth="1.5"  fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g id="Document" transform="translate(3.000000, 2.000000)" stroke={color}  strokeWidth="1.5" >
            <line x1="12.7162" y1="14.2234" x2="5.4962" y2="14.2234" id="Stroke-1"></line>
            <line x1="12.7162" y1="10.0369" x2="5.4962" y2="10.0369" id="Stroke-2"></line>
            <line x1="8.2513" y1="5.8601" x2="5.4963" y2="5.8601" id="Stroke-3"></line>
            <path d="M12.9086,0.7498 C12.9086,0.7498 5.2316,0.7538 5.2196,0.7538 C2.4596,0.7708 0.7506,2.5868 0.7506,5.3568 L0.7506,14.5528 C0.7506,17.3368 2.4726,19.1598 5.2566,19.1598 C5.2566,19.1598 12.9326,19.1568 12.9456,19.1568 C15.7056,19.1398 17.4156,17.3228 17.4156,14.5528 L17.4156,5.3568 C17.4156,2.5728 15.6926,0.7498 12.9086,0.7498 Z" id="Stroke-4"></path>
        </g>
    </g>
</svg>
  );
};
