import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { numberWithCommas } from '@todcode/components';

const AvailableAmount = ({ amount, t }) => (
  <Grid container sx={{ mb: '15px' }}>
    <Grid item xs={12} md={3}>
      <Box sx={{ background: '#fff', borderRadius: '12px', padding: '20px' }}>
        <Typography sx={{
          fontFamily: 'Inter',
          fontSize: '14px',
          color: '#8B8D97',
        }}
        >
          {t('availableAmount')}
        </Typography>
        <Typography sx={{
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: '20px',
          color: '#45464E',
        }}
        >
          {numberWithCommas(amount || 0)}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export default AvailableAmount;
