import { MButton } from '../Fab';
import { SvgClose } from '../Svg';

export const RejectButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <SvgClose />
    </MButton>
  );
};
