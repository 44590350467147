import React, { memo } from 'react';
import {
  Modal,
} from '@mui/material';
import { Form, Formik, FastField } from 'formik';

import Box from '@mui/material/Box';

import FooterModal from './component/FooterModal';
import HeaderModal from './component/HeaderModal';

import FieldType from './component/FieldType';
import { style } from './index.style';


import { Grid, Typography } from '@mui/material';
import Input from './component/Input';
import InputMask from 'react-input-mask';
import Checkbox from './component/Checkbox';
import TextModal from './component/TextModal';
import Select from './component/Select';
const get = require('lodash.get');

const SelaaModal = memo(({
  title, subTitle, params, initialValues, onSubmit, onClose, action, options, submitButton, t, readOnly, res,
}) => {
  return (
    <Modal
      onClose={onClose}
      open
      sx={{
        background: 'rgba(163, 163, 163, 0.2)',
        backdropFilter: 'blur(4px)',
      }}
    >
      { res ? (
        <Box>
          {res}
        </Box>
      ) : (
        <Box sx={style}>
          <Formik
            validate={false}
            validateOnChange={false}
            initialValues={initialValues}
            onSubmit={(values) => onSubmit(action, values)}
          >
            {({
              values, errors, handleChange, handleBlur, setFieldValue,
            }) => (
              <Form noValidate style={{ outline: 'none' }}>
                <HeaderModal title={title} />
                {
                            subTitle && (
                            <Box sx={{
                              p: 1,
                              m: 2,
                              color: '#8B8D97',
                              fontSize: '16px',
                              lineHeight: '19px',
                            }}
                            >
                              {subTitle}
                            </Box>
                            )
                        }
               <Grid container sx={{ px: 3, pb: 3 }}>
      {
        params.map(({
          param,
          label,
          type,
          mask,
          placeholder,
          selectOptionsParam,
          selectKey,
          selectLabel,
          defaultValue,
        }, index) => {
          const valueLodash = get(values, param);

          const errorLodash = get(errors, param);
          let field;

          if (type === 'input' && mask) {
            field = (
              <InputMask
                key={index}
                mask={mask}
                name={param}
                onChange={handleChange}
                onBlur={handleBlur}
                value={valueLodash}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    fullWidth
                    label={label}
                    value={valueLodash}
                    error={errorLodash}
                    placeholder={placeholder}
                  />
                )}
              </InputMask>
            );
          } else if (type === 'input') {
            field = (
              <Input
                key={index}
                name={param}
                label={label}
                onChange={handleChange}
                onBlur={handleBlur}
                value={valueLodash}
                error={errorLodash}
              />
            );
          } else if (type === 'checkbox') {
            field = (
              <Checkbox
                sx={{ px: '10px' }}
                key={index}
                label={label}
                onChange={(e) => setFieldValue(param, e.target.checked)}
                checked={valueLodash}
              />
            );
          } else if (type === 'text') {
            field = (
              <TextModal key={index} label={label} values={valueLodash} />
            );
          } else if (type === 'title') {
            field = (
              <TextModal key={index} label={label} values={valueLodash} fs="18px" fw="500" />
            );
          } else if (type === 'select') {
            field = (
              <Select
                key={index}
                name={param}
                label={label}
                options={options[selectOptionsParam]}
                selectKey={selectKey}
                optionLabel={selectLabel}
                value={valueLodash}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errorLodash}
                defaultValue={defaultValue}
              />
            );
          }

          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={12}
              sx={{
                alignItems: 'center',
                display: 'flex',
                py: '5px',
                borderBottom: type === 'text' ? '1px solid  #E1E1E1' : '0px',
                '&:last-child': {
                  borderBottom: '0',
                },
                '&:nth-child(5)': {
                  borderBottom: '0',
                },
              }}
            >
              {field}
            </Grid>
          );
        })
            }
    </Grid>
                {
                            !readOnly && (
                            <FooterModal
                              action={action}
                              onClick={onClose}
                              t={t('button.cancel')}
                              submitButton={submitButton}
                              t1={t('button.add')}
                            />
                            )
                        }
              </Form>
            )}
          </Formik>
        </Box>
      )}

    </Modal>
  );
});

export default SelaaModal;
