import React, { useState } from 'react';
import {
  Grid, Paper, Box,
} from '@mui/material';
import CardButtonRow from './component/CardButtonRow';
import CardRow from './component/CardRow';
import OpenButton from './component/OpenButton';
import ItemCard from './component/ItemCard';

const Card = ({
  data: item, params, visibleFields, onButtonClick,
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  return (
    <Box
      sx={{
        width: '340px', maxWidth: '98%', marginBottom: '15px', minHeight: '148px',
      }}
    >
      <Grid
        container
        component={Paper}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '12px',
          padding: '10px',
          boxShadow: 'none',
          transition: 'height 1s',
        }}
      >
        {
          params.slice(0, visibleFields).map((param, inx) => (
            <ItemCard item={item} key={inx} param={param} onButtonClick={onButtonClick} />))
        }
        <Grid item xs={12} sx={{ display: show ? 'block' : 'none', width: '100%' }}>
          {
            params.slice(visibleFields).map((param, inx) => (
              <ItemCard item={item} key={inx} param={param} onButtonClick={onButtonClick} />
            ))
          }
        </Grid>
        {
          params.length > visibleFields ? <OpenButton onClick={handleShow} /> : <> </>
        }
      </Grid>
    </Box>
  );
};

export default Card;
