import { Typography } from '@mui/material';
import React from 'react';

const HeaderModal = ({ title }) => {
  return (
    <Typography
      fontWeight="bold"
      sx={{
        p: 1,
        m: 2,
        fontSize: { xs: '18px', md: '20px' },
        lineHeight: '19px',
      }}
      variant="h3"
    >
      {title}
    </Typography>
  );
};

export default HeaderModal;
