import { styled, TextField } from '@mui/material';

const CssTextField = styled(TextField)({
  border: '0px solid transparent',
  '& .MuiInputBase-root': {
    background: 'rgba(239, 241, 249, 0.6)',
  },
  '& fieldset': {
    border: '0px solid transparent',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid transparent',
    },
    '&:hover fieldset': {
      border: '1px solid transparent',
      background: 'rgba(233, 236, 248, 0.3)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid transparent',
      backgroundColor: 'rgba(233, 236, 248, 0.5)',
    },
  },
  '& .MuiFilledInput-root': {
    background: 'rgba(239, 241, 249, 0.6)',
    borderRadius: '8px',
    border: '0px solid transparent',
    '&:hover': {
      backgroundColor: 'rgba(233, 236, 248, 0.9)',
    },
    '&:hover fieldset': {
      border: '0px solid #CFD2D4',
      backgroundColor: '#fff',
    },
    '&::before': {
      // content: '',
      display: 'none',
    },
  },

  '& .MuiFilledInput-root.Mui-focused': {
    backgroundColor: 'rgba(233, 236, 248, 0.9)',
    border: '0px solid #5570F1',
  },
  '& .MuiInputBase-root-MuiFilledInput-root': {
    backgroundColor: 'rgba(239, 241, 249, 0.6)',
    borderRadius: '8px',
  },

  '& .MuiInputLabel-root': { padding: '8px 0 0px 15px', fontFamily: 'Inter', fontSize: '16px' },
  '& .MuiInputLabel-root.Mui-focused': { color: '#5570F1' },

});

export default CssTextField;
