import { apiSlice } from '../../store/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    refresh: builder.mutation({
      query: (credentials) => {
        console.log({
          url: 'auth/refresh',
          method: 'POST',
          body: credentials,
        });
        return {
          url: 'auth/refresh',
          method: 'POST',
          body: credentials,
        };
      },
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
