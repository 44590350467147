import { TableCell } from '@mui/material';

const HeaderCell = ({ sx, ...props }) => (
  <TableCell
    {...props}
    sx={{
      borderBottom: '1px solid #E1E2E9',
      color: '#2C2D33',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      ...sx,
    }}
  />
);

export default HeaderCell;
