import { apiSlice } from '../../store/api/apiSlice';

export const historyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHistory: builder.query({
      query: () => '/history',
      invalidatesTags: ['History'],
    }),
  }),
});

export const {
  useGetHistoryQuery,
} = historyApiSlice;
