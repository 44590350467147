import LockResetIcon from '@mui/icons-material/LockReset';
import { MButton } from '../Fab';

export const ResetButton = ({ onClick }) => {
  return (
    <MButton onClick={onClick}>
      <LockResetIcon sx={{ color: '#fff' }} />
    </MButton>
  );
};
