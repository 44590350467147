import { Box, Button, Typography } from '@mui/material';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import React from 'react';

const ButtonCreateElement = ({ onClick, t, icon }) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'end',
      mb: '15px',
    }}
    >
      <Button
        variant="contained"
        startIcon={icon}
        type="submit"
        fullWidth
        sx={{
          display: 'flex',
          height: '36px',
          width: '205px',
          bgcolor: '#5570F1',
          borderRadius: '12px',
          textTransform: 'none',
          '&:hover': {
            bgcolor: '#97A5EB',
          },
        }}
        onClick={onClick}
      >
        <Typography sx={{ fontFamily: 'Inter', fontSize: '14px' }}>
          {t}
        </Typography>
      </Button>
    </Box>
  );
};

export default ButtonCreateElement;
