import { TransferButton } from '@todcode/components/src/component/TransferButton';
import { RedeemButton } from '@todcode/components/src/component/RedeemButton';
import Label from '@todcode/components/src/component/Label';
import { SvgDownload } from '@todcode/components/src/component/Svg';
import { Box, Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { capitalize } from '@todcode/components';

const baseParams = [
  {
    param: 'ownershipId',
    label: <Trans i18nKey="transactionTableHeads.id" />,
    align: 'center',
  },
  {
    param: 'dateTime',
    label: <Trans i18nKey="transactionTableHeads.date" />,
    align: 'center',
    transform: (value) => {
      const [date, timeZ] = value.split('T');
      const time = timeZ.split('+')[0];

      return `${date} ${time}`;
    },
  },
  {
    param: 'amount',
    label: <Trans i18nKey="transactionTableHeads.amount" />,
  },
  {
    param: 'type',
    label: <Trans i18nKey="transactionTableHeads.type" />,
    transform: capitalize,
  },
  {
    param: 'ownershipFileUrl',
    label: <Trans i18nKey="transactionTableHeads.pdf" />,
    transform: (value, item) => {
      if (!value) {
        return '-';
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Link to={`/transaction/${item.ownershipId}`}>File</Link>

          <MUILink href={value} download sx={{ ml: '5px' }}>
            <SvgDownload />
          </MUILink>
        </Box>
      );
    },
  },
  {
    param: 'active',
    label: <Trans i18nKey="transactionTableHeads.status" />,
    transform: (value) => {
      if (value) {
        return <Label value="Active" color="green" />;
      }

      return <Label value="Closed" color="blue" />;
    },
  },
];

export const transactionsParams = [
  ...baseParams,
  {
    label: <Trans i18nKey="transactionTableHeads.account" />,
    param: 'lenderName',
  },
  {
    label: <Trans i18nKey="transactionTableHeads.actions" />,
    align: 'center',
    buttons: [
      {
        Button: TransferButton,
        action: 'transfer',
        isActive: (item) => item.active && item.type === 'buy',
      },
      {
        Button: RedeemButton,
        action: 'redeem',
        isActive: (item) => item.active && item.redeemAllowed,
      },
    ],
  },
];

export const childrenTransactionsParams = [
  ...baseParams,
  {
    label: <Trans i18nKey="transactionTableHeads.actions" />,
    buttons: [
      {
        Button: TransferButton,
        action: 'transfer',
        isActive: (item) => item.active && !item.pending && item.type === 'buy',
      },
      {
        Button: RedeemButton,
        action: 'redeem',
        isActive: (item) => item.active && item.redeemAllowed,
      },
    ],
  },
];

export const buyModalParams = [
  {
    param: 'amount',
    label: <Trans i18nKey="modal.fieldTransactionCreate.amount" />,
    type: 'input',
  },
  {
    param: 'id',
    label: <Trans i18nKey="modal.fieldTransactionCreate.id" />,
    type: 'input',
  },
];

export const buyInitialValues = {
  amount: '',
  id: '',
};

export const transferModalParams = [
  {
    param: 'name',
    label: <Trans i18nKey="modal.fieldsTransfer.name" />,
    type: 'input',
  },
  {
    param: 'civilId',
    label: <Trans i18nKey="modal.fieldsTransfer.civilId" />,
    type: 'input',
  },
  {
    param: 'phone',
    label: <Trans i18nKey="modal.fieldsTransfer.phone" />,
    type: 'input',
  },
  {
    param: 'birthday',
    label: <Trans i18nKey="modal.fieldsTransfer.birthday" />,
    type: 'input',
    mask: '9999-99-99',
    placeholder: 'YYYY-MM-DD',
  },
];

export const transferInitialValues = {
  name: '',
  // civilId: '',
  // phone: '',
  // birthday: '',
};
